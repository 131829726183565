$( document ).ready(function() {
  $('select#SortBy').children('option[value="created-ascending"]').remove();
  
  $('select#SortBy').children('option[value="created-descending"]').remove();
  // var theColorIs = $('a').css("color");
});
$( window ).load(function() {
  $('select#SortBy').children('option[value="created-ascending"]').remove();
  
  $('select#SortBy').children('option[value="created-descending"]').remove();
  // var theColorIs = $('a').css("color");
});